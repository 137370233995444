import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/index";
import { VueReCaptcha } from "vue-recaptcha-v3";
import AOS from "aos";
import "aos/dist/aos.css";
import Amplify from "aws-amplify";
import aws_exports from "./aws-exports";

Vue.use(VueReCaptcha, { siteKey: "6Lf1l7UaAAAAAIsrRN0cP4kJ5FOIw-AZ3dbs-N0t" });
Vue.config.productionTip = false;
Vue.use(require("vue-moment"));
Amplify.configure(aws_exports);

new Vue({
  created() {
    AOS.init();
  },
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
