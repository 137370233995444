<template>
    <div style="position: relative;">
        <div style="background-color: #263238; padding: 10px 20px; color: white">
            <p class="ma-auto" style="font-size: 10px; font-weight: bold; text-align:center;">{{`${this.date}`}}</p>
            <p class="ma-auto" style="font-size: 8px; font-weight: bold; text-align:center;">POWERED BY <a href="https://www.canit.co.nz" target="_blank">CANIT LIMITED.</a></p>
        </div>
    </div>
</template>

<script>

export default {
    mounted() {
        this.getDate();
    },
    data: () => ({
        date: '2020'
    }),
    methods: {
        getDate() {
            let d = new Date();
            let year = d.getFullYear();

            this.date = `Copyright © Riccarton Mall Motel. ${year}`;
        }
    }
}
</script>
