<template>
    <div style="padding-top: 100px">
        <div class="desktop">
            <v-row no-gutters>
                <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                    <v-img :src="require('../assets/info1.jpg')"/>
                </v-col>
                <v-col style="position: relative; padding: 60px" cols="12" lg="6" md="6" sm="12" xs="12">
                    <div data-aos="fade-left" data-aos-duration="2000">
                        <h4>Renowned as the "Garden City" throughout New Zealand and the world, this beautiful city offers not just magnificent public and private parks and gardens, but so much more waiting to be discovered by visitors.</h4>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col style="position: relative; padding: 60px" cols="12" lg="6" md="6" sm="12" xs="12">
                    <div data-aos="fade-right" data-aos-duration="2000">
                        <p style="font-weight: bold">
                            Just across the road from our complex is Westfield Riccarton Mall. One of New Zealand’s largest malls, it offers a huge range of shopping options, a movie theatre, and a wide range of dining options. A short bus ride (from a stop outside our complex) will take you towards the heart of the city to explore the Botanical Gardens and Hagley Park to see the flowers blooming. Take a drive to New Brighton Pier or Sumner Beach and stroll along the beach, or visit the unique Re:Start shipping container shopping mall for a coffee or high-end shopping. Dining options are varied and plentiful and a range of quite unique nightlife venues are now springing up around the city in the wake of the 2010 and 2011 earthquakes, making Christchurch a unique place to visit that has been recently named by Lonely Planet as a great destination to experience.
                        </p>
                    </div>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                    <v-img :src="require('../assets/info2.jpg')"/>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                    <v-img :src="require('../assets/info3.jpg')"/>
                </v-col>
                <v-col style="position: relative; padding: 60px" cols="12" lg="6" md="6" sm="12" xs="12">
                    <div data-aos="fade-left" data-aos-duration="2000">
                        <p style="font-weight: bold">
                            Christchurch has many outstanding attractions. See unique New Zealand wildlife at Willowbank or African animals at Orana Park, take a cruise on the Lyttelton Harbour and swim with the rare Hector’s Dolphin, learn about the history and culture of the city at Canterbury Museum, or visit the International Antarctic Centre to experience indoor snow & ice, meet penguins, and take an outdoor Hagglund Ride. 
                        </p>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col style="position: relative; padding: 60px" cols="12" lg="6" md="6" sm="12" xs="12">
                    <div data-aos="fade-right" data-aos-duration="2000">
                        <p style="font-weight: bold">
                            Christchurch is the gateway to the broader Canterbury region and is an outstanding base from which to explore further and get into the skiing, walking and tramping, sightseeing, recreational boating, fishing, mountain biking, and other activities that are on offer within easy reach.
                        </p>
                    </div>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                    <v-img :src="require('../assets/info4.jpg')"/>
                </v-col>
            </v-row>
        </div>
        
        <div class="phone">
            <v-row no-gutters>
                <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                    <v-img :src="require('../assets/info1.jpg')"/>
                </v-col>
                <v-col style="position: relative; padding: 60px" cols="12" lg="6" md="6" sm="12" xs="12">
                    <div data-aos="fade-left" data-aos-duration="2000">
                        <h4>Renowned as the "Garden City" throughout New Zealand and the world, this beautiful city offers not just magnificent public and private parks and gardens, but so much more waiting to be discovered by visitors.</h4>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                    <v-img :src="require('../assets/info2.jpg')"/>
                </v-col>
                <v-col style="position: relative; padding: 60px" cols="12" lg="6" md="6" sm="12" xs="12">
                    <div data-aos="fade-right" data-aos-duration="2000">
                        <p style="font-weight: bold">
                            Just across the road from our complex is Westfield Riccarton Mall. One of New Zealand’s largest malls, it offers a huge range of shopping options, a movie theatre, and a wide range of dining options. A short bus ride (from a stop outside our complex) will take you towards the heart of the city to explore the Botanical Gardens and Hagley Park to see the flowers blooming. Take a drive to New Brighton Pier or Sumner Beach and stroll along the beach, or visit the unique Re:Start shipping container shopping mall for a coffee or high-end shopping. Dining options are varied and plentiful and a range of quite unique nightlife venues are now springing up around the city in the wake of the 2010 and 2011 earthquakes, making Christchurch a unique place to visit that has been recently named by Lonely Planet as a great destination to experience.
                        </p>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                    <v-img :src="require('../assets/info3.jpg')"/>
                </v-col>
                <v-col style="position: relative; padding: 60px" cols="12" lg="6" md="6" sm="12" xs="12">
                    <div data-aos="fade-left" data-aos-duration="2000">
                        <p style="font-weight: bold">
                            Christchurch has many outstanding attractions. See unique New Zealand wildlife at Willowbank or African animals at Orana Park, take a cruise on the Lyttelton Harbour and swim with the rare Hector’s Dolphin, learn about the history and culture of the city at Canterbury Museum, or visit the International Antarctic Centre to experience indoor snow & ice, meet penguins, and take an outdoor Hagglund Ride. 
                        </p>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                    <v-img :src="require('../assets/info4.jpg')"/>
                </v-col>
                <v-col style="position: relative; padding: 60px" cols="12" lg="6" md="6" sm="12" xs="12">
                    <div data-aos="fade-right" data-aos-duration="2000">
                        <p style="font-weight: bold">
                            Christchurch is the gateway to the broader Canterbury region and is an outstanding base from which to explore further and get into the skiing, walking and tramping, sightseeing, recreational boating, fishing, mountain biking, and other activities that are on offer within easy reach.
                        </p>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>

export default {
    mounted () {
        window.scroll(0, 0)
    },
}
</script>

<style scoped>
@media (min-width: 800px) {
  .phone {
      display: none;
  }

  .desktop {
      display: block;
  }
}

@media (max-width: 801px) {
  .phone {
      display: block;
  }

  .desktop {
      display: none;
  }
}
</style>


