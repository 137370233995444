import axios from "axios";
// import store from "../store/auth";
const url =
  "https://uh0g0yaw0f.execute-api.ap-southeast-2.amazonaws.com/Development/emailing";

const apiClient = {
  url: url,
  api: axios.create({
    method: "post",
    baseURL: url,
  }),
};

export default apiClient;
