<template>

	<div class="d-flex" style="background-color: #105E30; position: fixed; width: 100%; z-index: 3;">

		<div @click="navigate('')" style="background-color: #105E30; display:flex; cursor: pointer;">

			<div class="text-left" v-if="isMobile()">

				<v-img class="logo ml-2 mt-2" :src="require('../assets/logo.svg')" />

				<v-card-title class="title2"> Riccarton Mall Motel </v-card-title>

			</div>

			<v-img
				v-if="!isMobile()"
				style="margin: auto"
				class="logo"
				:src="require('../assets/logo.svg')"
			/>

			<!-- <v-divider vertical color="grey" inset></v-divider> -->

			<v-card-title class="separator" v-if="!isMobile()">|</v-card-title>

			<v-card-title v-if="!isMobile()" class="title"> Riccarton Mall Motel </v-card-title>

		</div>

		<v-spacer />

		<div class="nav">

			<v-btn
				:color="url == 'home' ? '#ddd':'#F5F5F5'"
				tile
				@click="navigate('')"
				elevation="0"
				height="100"
				style="width: 150px"
			>
				 HOME
			</v-btn>

			<v-btn
				:color="url == 'information' ? '#ddd':'#F5F5F5'"
				tile
				@click="navigate('information')"
				elevation="0"
				height="100"
				style="width: 150px"
			>
				 Attractions
			</v-btn>

			<v-btn
				:color="url == 'facilities' ? '#ddd':'#F5F5F5'"
				tile
				@click="navigate('facilities')"
				elevation="0"
				height="100"
				style="width: 150px"
			>
				 FACILITIES
			</v-btn>

				<v-btn
				:color="url == 'contact' ? '#ddd':'#F5F5F5'"
				tile
				@click="navigate('contact')"
				elevation="0"
				height="100"
				style="width: 150px"
			>
				 CONTACT US
			</v-btn>

			<a :href="date" target="_blank">

				<v-btn elevation="0" height="100" style="width: 150px; color: white" color="#263238">
					 BOOK ONLINE
				</v-btn>

			</a>

		</div>

		<div class="icon">

			<v-btn tile @click="toggleDrawer" elevation="0" height="100" style="width: 120px">

				<v-icon>mdi-menu</v-icon>

			</v-btn>

		</div>

	</div>

</template>

<script>
import moment from "moment";
import { mapGetters, mapMutations } from "vuex";

export default {
	mounted() {
		this.getDate();
		this.initNavigate();
	},
	computed: {
		...mapGetters(['url']), },
		data: () => ({
			date: '', home: false, information: false, facilities: false, contact: false }),
	methods: {
		...mapMutations(['toggleDrawer', 'navigator']),
		isMobile(){
			if (this.$vuetify.breakpoint.width < 500 ){ return true } return false
			},
		getDate() { let d = moment().format('L'); let day = d.split('/')[1]; let month = d.split('/')[0]; let
			year = d.split('/')[2]; this.date = `https://secure.staah.com/common-cgi/properties/b2c/Booknow.pl?action=showpage&MotelId=2474&checkIn=${year}-${month}-${day}&unk=1292`;
		},
		navigate(path) {
			const url = window.location.pathname.split('/');
			if (url[1] !== path) {
				this.$router.push(`/${path}`);
			}
			if (path == '') {
				this.navigator('home');
			}else { this.navigator(path); }
		},
		initNavigate() {
			const url = window.location.pathname.split("/");
			if (url[1] == "") {
				this.navigator("home");
			} else {
				this.navigator(url[1]);
			}	
			},
  }
}
</script>

<style scoped>
	@media only screen and (min-width: 1199px) {
		.logo {
			width: 150px;
			height: 50px;
		}
		.nav {
			display: flex;
		}
		.icon {
			display: none;
		}
	}
	@media only screen and (max-width: 1200px) {
		.logo {
			width: 100px;
			height: 50px;
		}
		.nav {
			display: none;
		}
		.icon {
			display: block;
		}
	}
	.title {
		color: white;
		font-family: 'Gill Sans' !important;
		font-size: calc(1vw + 1vh + 1vmin) !important;
	}

	.title2 {
		color: white;
		font-family: 'Gill Sans' !important;
		font-size: 20px !important;
		margin-top: -25px;
	}
	.separator {
		color: white;
		font-family: 'Gill Sans' !important;
	}
</style>

