<template>
	<div>
		<v-dialog v-model="prompt" width="500">
			<v-card class="pa-6">
				<h4 class="text-center mb-4">{{ promptText }}</h4>
				<v-row>
					<v-col>
						<v-btn dark block @click="prompt = false">No</v-btn>
					</v-col>
					<v-col>
						<v-btn dark block @click="promptFunction()">Yes</v-btn>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="notification">
			<h5 class="text-center">{{ notificationText }}</h5>
		</v-snackbar>
		<v-dialog v-model="metaDescriptionDialog">
			<v-progress-linear v-if="loading" indeterminate color="green" height="25"></v-progress-linear>
			<v-card class="pa-6" height="400px">
				<div class="d-flex">
					<v-card-title>Edit Meta Description</v-card-title>
					<v-spacer />
					<!-- <v-btn
            dark
            color="#263238"
            @click="save"
            class="mr-4"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn> -->
					<v-btn dark color="#263238" @click="metaDescriptionDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>
				<v-card-text><i>Recommended Length 160 characters</i></v-card-text>
				<v-card-text
					><b
						>Character count:
						<span :style="metaDescription.length > 160 ? `color:red` : `color:black`">{{
							metaDescription.length
						}}</span>
						/ 160</b
					></v-card-text
				>
				<v-text-field filled v-model="metaDescription"></v-text-field>
			</v-card>
			<v-btn :disabled="loading" class="pa-6" block @click="saveInit" color="#105E30" dark
				>Submit</v-btn
			>
		</v-dialog>
		<v-dialog v-model="facilitiesDialog">
			<v-progress-linear v-if="loading" indeterminate color="green" height="25"></v-progress-linear>
			<v-card class="pa-6">
				<div class="d-flex">
					<v-card-title>Edit Facilities Description</v-card-title>
					<v-spacer />
					<!-- <v-btn
            dark
            color="#263238"
            @click="save"
            class="mr-4"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn> -->
					<v-btn dark color="#263238" @click="facilitiesDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>
				<v-textarea clearable v-model="facilitiesDescription" height="500" filled />
			</v-card>
			<v-btn :disabled="loading" class="pa-6" block @click="save" color="#105E30" dark
				>Submit</v-btn
			>
		</v-dialog>
		<v-dialog v-model="carouselDialog">
			<v-progress-linear v-if="loading" indeterminate color="green" height="25"></v-progress-linear>
			<v-card class="pa-6" height="800" style="overflow: scroll">
				<div class="d-flex">
					<v-card-title>Edit Slider</v-card-title>
					<v-spacer />
					<div>
						<!-- <v-btn
              :disabled="loading"
              class="mr-3"
              style="cursor:pointer"
              @click="saveSliderSettings()"
              dark
              color="#263238"
            >
              <v-icon class="ma-auto">mdi-check</v-icon>
            </v-btn> -->

						<v-btn
							style="cursor: pointer"
							@click="
								carouselDialog = false;
								loading = false;
							"
							dark
							color="#263238"
						>
							<v-icon class="ma-auto">mdi-close</v-icon>
						</v-btn>
					</div>
				</div>
				<v-form>
					<v-card-subtitle>Main Slider Image</v-card-subtitle>
					<v-row class="mb-6">
						<v-col cols="3">
							<v-card style="height: 300px" color="#eee">
								<label
									for="fileInputSliderMain"
									style="position: absolute; z-index: 1; top: 10px; right: 10px; cursor: pointer"
								>
									<div class="pa-3" style="background-color: #eee; border-radius: 3px">
										<v-icon>mdi-pencil</v-icon>
									</div>
								</label>
								<input
									id="fileInputSliderMain"
									hidden
									type="file"
									accept="image/png, image/gif, image/jpeg"
									@change="e => fileToB64(e, 'sliderMainImage')"
								/>
								<v-img style="width: 100%; height: 100%" :src="carousel.main" />
							</v-card>
						</v-col>
					</v-row>
					<v-card-subtitle>Slides</v-card-subtitle>
					<v-row>
						<v-col cols="3">
							<v-card height="300" style="position: relative" color="#eee">
								<label
									for="fileInputSlider"
									class="pa-6"
									style="
										cursor: pointer;
										position: absolute;
										left: 50%;
										top: 50%;
										transform: translate(-50%, -50%);
									"
								>
									<v-icon size="150" class="ma-auto">mdi-plus</v-icon>
								</label>
								<input
									id="fileInputSlider"
									hidden
									type="file"
									accept="image/png, image/gif, image/jpeg"
									@change="e => fileToB64(e, 'carouselImages')"
								/>
							</v-card>
						</v-col>
						<v-col cols="3" v-for="(img, index) in carousel.images" :key="index">
							<v-card height="300" style="position: relative" color="#eee">
								<v-btn
									style="position: absolute; top: 10px; right: 10px; z-index: 1"
									@click="deleteSliderImage(index)"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
								<v-img style="height: 100%; width: 100%" :src="img" />
							</v-card>
						</v-col>
					</v-row>
				</v-form>
			</v-card>

			<v-btn
				:disabled="loading"
				class="pa-6"
				block
				@click="saveSliderSettings()"
				color="#105E30"
				dark
				>Submit</v-btn
			>
		</v-dialog>
		<v-dialog v-model="objectDialog" width="1000">
			<v-progress-linear v-if="loading" indeterminate color="green" height="25"></v-progress-linear>
			<v-card class="pa-6" height="800" style="overflow: scroll">
				<div class="d-flex">
					<v-card-title>Edit Home Content</v-card-title>
					<v-spacer />
					<v-btn @click="resetObject()" color="#263238" dark>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>
				<v-form ref="formContent">
					<v-row>
						<v-col>
							<v-text-field
								:rules="[v => !!v || 'Please Enter a value']"
								v-model="title"
								filled
								label="title"
							/>
						</v-col>
						<v-col>
							<v-text-field
								:rules="[v => !!v || 'Please Enter a value']"
								v-model="subtitle"
								filled
								label="subtitle"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-text-field
								:rules="[v => !!v || 'Please Enter a value']"
								v-model="description"
								filled
								label="Description"
							/>
						</v-col>
					</v-row>
					<v-card-subtitle>Main Image</v-card-subtitle>
					<v-row class="mb-6">
						<v-col cols="4">
							<v-card height="300" style="position: relative" color="#eee">
								<label
									for="fileInputContentMain"
									style="position: absolute; z-index: 1; top: 10px; right: 10px; cursor: pointer"
								>
									<div class="pa-3" style="background-color: #eee; border-radius: 3px">
										<v-icon>mdi-pencil</v-icon>
									</div>
								</label>
								<v-btn
									v-if="mainObjectImage"
									@click="removeImages(null, 'mainObjectImage')"
									class="pa-3"
									style="position: absolute; z-index: 1; top: 10px; left: 10px; cursor: pointer"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
								<input
									id="fileInputContentMain"
									hidden
									type="file"
									accept="image/png, image/gif, image/jpeg"
									@change="e => fileToB64(e, 'mainObjectImage')"
								/>
								<v-img style="width: 100%; height: 100%" :src="mainObjectImage" />
							</v-card>
						</v-col>
					</v-row>
					<v-card-subtitle>Item Images</v-card-subtitle>
					<v-row>
						<v-col cols="4">
							<v-card height="300" style="position: relative" color="#eee">
								<label
									for="fileInputContent"
									class="pa-6"
									style="
										cursor: pointer;
										position: absolute;
										left: 50%;
										top: 50%;
										transform: translate(-50%, -50%);
									"
								>
									<v-icon size="150" class="ma-auto">mdi-plus</v-icon>
								</label>
								<input
									id="fileInputContent"
									hidden
									type="file"
									accept="image/png, image/gif, image/jpeg"
									@change="e => fileToB64(e, 'objectImages')"
								/>
							</v-card>
						</v-col>
						<v-col cols="4" v-for="(img, index) in objectImages" :key="index">
							<v-card height="300" style="position: relative" color="#eee">
								<v-btn
									style="position: absolute; top: 10px; right: 10px; z-index: 1"
									@click="removeImages(index, 'objectImages')"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
								<v-img style="height: 100%; width: 100%" :src="img" />
							</v-card>
						</v-col>
					</v-row>
				</v-form>
			</v-card>
			<v-btn :disabled="loading" class="pa-6" block @click="addObject" color="#105E30" dark
				>Submit</v-btn
			>
		</v-dialog>
		<v-container style="padding-top: 150px; padding-bottom: 150px">
			<v-row class="mb-6">
				<v-col cols="6"><h1 style="color: #263238">Admin Panel</h1></v-col>
				<v-col cols="6" class="text-right">
					<v-btn
						@click="
							prompt = true;
							action = `logout`;
							promptText = `Are you sure you want to logout?`;
						"
						class="mb-4"
						dark
						color="#263238"
					>
						LOGOUT
						<v-icon class="ml-3">mdi-logout</v-icon></v-btn
					>
				</v-col>
			</v-row>

			<div class="mb-8">
				<div class="d-flex mt-16 mb-6">
					<h2 style="color: #105e30">META DESCRIPTION</h2>
					<v-spacer />
					<v-btn @click="metaDescriptionDialog = true" color="#105E30" dark
						>Edit Meta Description</v-btn
					>
				</div>
				<v-row>
					<v-col>
						<h4 v-if="metaDescription" style="word-wrap: break-all; white-space: pre-wrap">
							{{ metaDescription }}
						</h4>
						<h4 v-else style="word-wrap: break-all; white-space: pre-wrap">
							There are no meta descriptions saved yet.
						</h4>
					</v-col>
				</v-row>
			</div>

			<div class="mb-6">
				<div class="d-flex mb-4">
					<h2 style="color: #105e30">HOME SLIDER</h2>
					<v-spacer />
					<v-btn color="#105E30" dark @click="carouselDialog = true">Edit Home Slider</v-btn>
				</div>
				<v-carousel v-model="model" style="background-color: #eee">
					<v-carousel-item v-for="(img, i) in carousel.images" :key="i">
						<v-sheet :color="img" height="100%" tile>
							<v-row class="fill-height ma-0" align="center" justify="center">
								<div style="width: 100%; height: 100%">
									<v-img style="width: 100%; height: 100%" :src="img" />
								</div>
							</v-row>
						</v-sheet>
					</v-carousel-item>
				</v-carousel>
			</div>
			<div>
				<div class="d-flex mb-4">
					<h2 style="color: #105e30">HOME CONTENT</h2>
					<v-spacer />
					<v-btn
						color="#105E30"
						dark
						@click="
							objectDialog = true;
							object = null;
						"
					>
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</div>
				<div>
					<v-row v-if="objects.length > 0">
						<v-col cols="4" v-for="(item, index) in objects" :key="index">
							<v-card color="#eee">
								<v-btn
									@click="setObject(item)"
									color="#263238"
									dark
									style="position: absolute; top: 10px; right: 10px; z-index: 1"
								>
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
								<v-btn
									@click="
										prompt = true;
										action = `delete`;
										selectedObject = item.index;
										promptText = `Are you sure you want to delete?`;
									"
									color="#263238"
									dark
									style="position: absolute; top: 10px; left: 10px; z-index: 1"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
								<div style="width: 100%; height: 350px; position: relative">
									<v-img
										v-if="item.main"
										:src="item.main"
										style="border-radius: 5px; width: 100%; height: 100%"
									/>
								</div>
								<div
									class="pa-4"
									style="
										position: absolute;
										bottom: 20px;
										background-color: rgba(0, 0, 0, 0.6);
										width: 100%;
										color: white;
									"
								>
									<h5>{{ item.title }}</h5>
									<h5>{{ item.subtitle }}</h5>

									<p class="mt-2" style="font-size: 10px">{{ item.description }}</p>
								</div>
							</v-card>
						</v-col>
					</v-row>
					<p v-else>No Content Created.</p>
				</div>
			</div>

			<div>
				<div class="d-flex mt-16 mb-6">
					<h2 style="color: #105e30">FACILITIES</h2>
					<v-spacer />
					<v-btn @click="facilitiesDialog = true" color="#105E30" dark
						>Edit Facilities Description</v-btn
					>
				</div>
				<v-row>
					<v-col>
						<h4 style="word-wrap: break-all; white-space: pre-wrap">{{ facilitiesDescription }}</h4>
					</v-col>
				</v-row>
			</div>
		</v-container>
		<v-dialog v-model="errorDialog" max-width="300">
			<v-card color="red" max-width="300" dark>
				<v-card-title> Error </v-card-title>
				<v-card-text>
					{{ errorMessage }}
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text dark @click="errorDialog = !errorDialog"> Okay </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import { API, Auth } from 'aws-amplify';
	import {
		getCarousel,
		listObjects,
		getFacilities,
		deleteImage,
		getInitItems,
	} from '../graphql/queries';
	import {
		updateObject,
		updateCarousel,
		saveImage,
		updateFacilities,
		deleteObject,
		addObject,
		saveInitItems,
	} from '../graphql/mutations';

	export default {
		mounted() {
			this.getInitItems();
			this.getCarousel();
			this.listObjects();
			this.getFacilities();
		},
		methods: {
			async getInitItems() {
				let result = await API.graphql({
					query: getInitItems,
					authMode: 'API_KEY',
				});

				if (result.data.getInitItems == null) return;
				const metaDescription = result.data.getInitItems.metaDescription;
				this.metaDescription = metaDescription;

				//Set Meta Description in index.html
				const metaEl = document.querySelector('head meta[name="description"]');

				metaEl.setAttribute('content', metaDescription);
			},
			promptFunction() {
				this.prompt = true;

				if (this.action == 'logout') {
					this.signOut();
				} else if (this.action == 'delete') {
					this.deleteObject(this.selectedObject);
				}

				this.selectedObject = null;
				this.prompt = false;
			},
			deleteSliderImage(index) {
				if (this.carousel.images[index].includes('https://')) {
					this.deleted_sliders.push(this.carousel.images[index]);
				}

				if (index > -1) {
					this.carousel.images.splice(index, 1);
				}
			},
			async saveSliderSettings() {
				this.loading = true;
				if (this.carousel.main && !this.carousel.main.includes('https://')) {
					let main = this.carousel.main;
					let img_split = main.split('#');
					let data = {
						file_name: 'MAIN',
						base64: img_split[0],
					};

					try {
						await API.graphql({
							query: saveImage,
							variables: {
								input: data,
							},
						});
					} catch (error) {
						this.errorDialog = true;
						this.errorMessage = 'Photo size too large.';
						return;
					}
					let file_name =
						'MAIN' +
						(img_split[1] == 'jpg' || img_split[1] == 'JPG' ? '.jpeg' : '.' + img_split[1]);
					this.carousel.main =
						'https://s3.ap-southeast-2.amazonaws.com/rmm.co.nz/site_images/' + file_name;
				}

				this.deleted_sliders.forEach(async image => {
					let image_to_be_deleted = image.split('/');
					API.graphql({
						query: deleteImage,
						variables: {
							input: {
								file_name: 'site_images/' + image_to_be_deleted[5],
								base64: '',
							},
						},
					}).then(res => {
						this.notification = true;
						this.notificationText = 'UPDATED';
					});
				});

				let updated_images_list = [];
				let image = this.carousel.images;
				for (var i = 0; i < this.carousel.images.length; i++) {
					if (!image[i].includes('https://')) {
						let img_split = image[i].split('#');
						let data = {
							file_name: img_split[1].split('.')[0],
							base64: img_split[0],
						};

						try {
							await API.graphql({
								query: saveImage,
								variables: {
									input: data,
								},
							});
						} catch (error) {
							this.errorDialog = true;
							this.errorMessage = 'Photo size too large.';
						}
						let file_name_split = img_split[1].split('.');
						let file_name =
							file_name_split[0] +
							(file_name_split[1] == 'jpg' || file_name_split[1] == 'JPG'
								? '.jpeg'
								: '.' + file_name_split[1]);
						updated_images_list.push(
							'https://s3.ap-southeast-2.amazonaws.com/rmm.co.nz/site_images/' + file_name
						);
					} else {
						updated_images_list.push(image[i]);
					}
				}
				this.carousel.images = updated_images_list;
				let res = await API.graphql({
					query: updateCarousel,
					variables: {
						input: this.carousel,
					},
				}).then(res => {
					this.carouselDialog = false;
					this.loading = false;
				});

				this.deleted_sliders = [];
			},
			async signOut() {
				try {
					await Auth.signOut();
					this.$router.push('/');
				} catch (error) {
					console.log(error);
				}
			},
			async fileToB64(event, type) {
				let file_name = event.target.files[0].name;
				let file_type = event.target.files[0].type;
				//convert file to 1920 1080
				let convert = require('image-file-resize');
				let file = await convert({
					file: event.target.files[0],
					width: 1920,
					height: 1080,
				})
					.then(resp => {
						return resp;
					})
					.catch(error => {
						console.log(error);
					});

				if (event.target.files[0]) {
					var reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onloadend = async () => {
						if (type == 'mainObjectImage') {
							if (file_type) {
								this.mainObjectImage = reader.result + '#' + file_type.split('/')[1];
							} else {
								//TODO: ERROR!!!!!!!
								this.errorDialog = true;
								this.errorMessage = 'Please Add Images';
							}
						} else if (type == 'objectImages') {
							this.objectImages.push(reader.result + '#' + file_name);
						} else if (type == 'carouselImages') {
							this.carousel.images.push(reader.result + '#' + file_name);
						} else if (type == 'sliderMainImage') {
							if (file_type) {
								this.carousel.main = reader.result + '#' + file_type.split('/')[1];
							} else {
								//TODO: ERROR!!!
								this.errorDialog = true;
								this.errorMessage = 'Please Add Images';
							}
						}
					};
					event.target.value = '';
				}
			},
			removeImages(index, type) {
				if (type == 'mainObjectImage') {
					this.mainObjectImage = null;
				} else if (type == 'objectImages') {
					this.objectImages.splice(index, 1);
				}
			},

			// Carousel
			async getCarousel() {
				let res = await API.graphql({
					query: getCarousel,
				});

				let carousel_data = res.data.getCarousel;

				if (carousel_data.main && carousel_data.images) {
					this.carousel = carousel_data;
				} else {
					this.carousel = {
						main: 'https://images.ctfassets.net/hrltx12pl8hq/3MbF54EhWUhsXunc5Keueb/60774fbbff86e6bf6776f1e17a8016b4/04-nature_721703848.jpg?fit=fill&w=480&h=270',
						images: [
							'https://images.ctfassets.net/hrltx12pl8hq/3MbF54EhWUhsXunc5Keueb/60774fbbff86e6bf6776f1e17a8016b4/04-nature_721703848.jpg?fit=fill&w=480&h=270',
							'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png',
							'https://images.ctfassets.net/hrltx12pl8hq/3MbF54EhWUhsXunc5Keueb/60774fbbff86e6bf6776f1e17a8016b4/04-nature_721703848.jpg?fit=fill&w=480&h=270',
							'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png',
							'https://images.ctfassets.net/hrltx12pl8hq/3MbF54EhWUhsXunc5Keueb/60774fbbff86e6bf6776f1e17a8016b4/04-nature_721703848.jpg?fit=fill&w=480&h=270',
							'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png',
						],
					};
				}
			},

			// Objects
			async listObjects() {
				let res = await API.graphql({
					query: listObjects,
				}).then(res => {
					return res.data.listObjects.items;
				});

				this.objects = res;
			},
			setObject(obj) {
				this.object = obj;
				this.title = obj.title;
				this.subtitle = obj.subtitle;
				this.description = obj.description;
				this.mainObjectImage = obj.main;
				this.objectImages = obj.images;
				this.objectDialog = true;
			},

			async saveObjectImages(data) {
				let img_split = data.main.split('#');
				let main_image_link = '';
				//Save the main object image
				if (!data.main.includes('https://')) {
					let obj_main_data = {
						file_name: 'objects/' + data.title + '/MAIN',
						base64: img_split[0],
					};
					try {
						let res = await API.graphql({
							query: saveImage,
							variables: {
								input: obj_main_data,
							},
						});
					} catch (error) {
						this.errorDialog = true;
						this.errorMessage = 'Photo size too large.';
					}

					let file_name =
						'MAIN' +
						(img_split[1] == 'jpg' || img_split[1] == 'JPG' ? '.jpeg' : '.' + img_split[1]);
					main_image_link =
						'https://s3.ap-southeast-2.amazonaws.com/rmm.co.nz/site_images/objects/' +
						data.title +
						'/' +
						file_name;
				} else {
					main_image_link = data.main;
				}

				//deleted object images
				if (this.object_delete_images.length > 0) {
					this.object_delete_images.forEach(async image => {
						let image_to_be_deleted = image.split('/');
						API.graphql({
							query: deleteImage,
							variables: {
								input: {
									file_name: 'site_images/objects/' + data.title + '/' + image_to_be_deleted[5],
									base64: '',
								},
							},
						}).then(res => {
							this.notification = true;
							this.notificationText = 'UPDATED';
						});
					});
				}

				//save images list
				let updated_images_list = [];
				let image = data.images;

				for (var i = 0; i < data.images.length; i++) {
					let title = data.title;
					if (!image[i].includes('https://')) {
						let img_split = image[i].split('#');
						let obj_images_data = {
							file_name: 'objects/' + title + '/' + img_split[1].split('.')[0],
							base64: img_split[0],
						};
						try {
							await API.graphql({
								query: saveImage,
								variables: {
									input: obj_images_data,
								},
							});
						} catch (error) {
							this.errorDialog = true;
							this.errorMessage = 'Photo size too large.';
							this.loading = false;
						}
						let file_name_split = img_split[1].split('.');
						let file_name =
							file_name_split[0] +
							(file_name_split[1] == 'jpg' || file_name_split[1] == 'JPG'
								? '.jpeg'
								: '.' + file_name_split[1]);
						updated_images_list.push(
							'https://s3.ap-southeast-2.amazonaws.com/rmm.co.nz/site_images/objects/' +
								title +
								'/' +
								file_name
						);
					} else {
						updated_images_list.push(image[i]);
					}
				}

				data.images = updated_images_list;
				data.main = main_image_link;
				this.object_delete_images = [];

				return data;
			},

			async addObject() {
				this.loading = true;
				const valid = this.$refs.formContent.validate();
				if (valid) {
					let data = {
						title: this.title,
						subtitle: this.subtitle,
						description: this.description,
						main: this.mainObjectImage,
						images: this.objectImages,
					};
					if (!this.mainObjectImage || this.objectImages.length <= 0) {
						//checks images
						this.errorDialog = true;
						this.errorMessage = 'Please Add Images';
						this.loading = false;
						return;
					}
					let new_data = await this.saveObjectImages(data);

					let object = null;
					if (this.object) {
						new_data['index'] = this.object.index;
						object = await API.graphql({
							query: updateObject,
							variables: {
								input: new_data,
							},
						}).then(res => {
							this.loading = false;
							return res.data.updateObject;
						});
					} else {
						object = await API.graphql({
							query: addObject,
							variables: {
								input: new_data,
							},
						}).then(res => {
							this.loading = false;
							return res.data.addObject;
						});
					}

					if (object) {
						if (this.object) {
							const index = this.objects.findIndex(el => {
								return el.index == object.index;
							});
							this.objects.splice(index, 1, object);
						} else {
							this.objects.push(object);
						}
						this.notification = true;
						this.notificationText = 'UPDATED';
					} else {
						this.notification = true;
						this.notificationText = 'ERROR UPDATING';
					}

					this.object = null;
					this.resetObject();
				}
				this.loading = false;
			},
			async deleteObject(index) {
				console.log(index);
				let res = await API.graphql({
					query: deleteObject,
					variables: {
						index: index,
					},
				});

				console.log(res);

				const i = this.objects.findIndex(el => {
					return el.index == index;
				});

				this.objects.splice(i, 1);
			},
			resetObject() {
				this.objectDialog = false;
				this.title = '';
				this.subtitle = '';
				this.description = '';
				this.mainObjectImage = null;
				this.objectImages = [];
				this.loading = false;
			},

			// Facilities
			async getFacilities() {
				this.facilitiesDescription = await API.graphql({
					query: getFacilities,
				}).then(res => {
					return res.data.getFacilities.description;
				});
			},
			async saveInit() {
				this.loading = true;
				let result = await API.graphql({
					query: saveInitItems,
					variables: {
						input: {
							metaDescription: this.metaDescription,
						},
					},
				});

				if (result.data.saveInitItems) {
					this.notification = true;
					this.notificationText = 'UPDATED';
					this.loading = false;
				}

				this.metaDescriptionDialog = false;
			},
			async save() {
				this.loading = true;
				await API.graphql({
					query: updateFacilities,
					variables: {
						input: { description: this.facilitiesDescription },
					},
				}).then(res => {
					this.notification = true;
					this.notificationText = 'UPDATED';
					this.loading = false;
				});

				this.facilitiesDialog = false;
			},
		},

		computed: {
			metaDescription: {
				get() {
					return this.$store.getters.metaDescription;
				},
				set(val) {
					this.$store.commit('setMetaDescription', val);
				},
			},
		},
		data: () => ({
			loading: false,
			notification: false,
			notificationText: '',
			carouselDialog: false,
			model: 0,
			carousel: {},
			errorDialog: false,
			errorMessage: '',
			objectDialog: false,
			object: null,
			title: '',
			subtitle: '',
			description: '',
			mainObjectImage: null,
			objectImages: [],
			objects: [],

			metaDescriptionDialog: false,
			facilitiesDialog: false,
			facilitiesDescription: '',
			deleted_sliders: [],
			object_delete_images: [],
			prompt: false,
			action: '',
			selectedObject: null,
			promptText: '',
		}),
	};
</script>
