<template>
  <v-app>
    <Navigation/>
    <Prompt/>
    <Header/>
    <v-main>
      <router-view></router-view>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import Header from './components/Header';
import Footer from './components/Footer';
import Prompt from './components/prompt';
import Navigation from './components/Navigation';

export default {
  name: 'App',

  components: {
    Header,
    Footer,
    Prompt,
    Navigation
  },

  data: () => ({
    //
  }),
};
</script>

<style>
  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  html {
    scroll-behavior: smooth;
  }
</style>