<template>
  <div style="min-height: 100vh; background-color: #eee;">
    <div style="height: 60vh">
      <v-img
        height="100%"
        :src="require('../assets/facilities.jpg')"
      />
    </div>
    <div style="background-color: #263238; padding: 100px 40px; color: white">
      <p
        style="text-align:center; width: 80%"
        class="mb-10 mt-6 mr-auto ml-auto"
      >Riccarton Mall Motel makes luxury affordable with its comfortable, modern and well-appointed studios and apartments. Our premier location next to Westfield Riccarton Mall is also directly on Christchurch’s major bus routes, making the rest of the city easily accessible from your Riccarton base.</p>
      <h2 style="text-align:center">FACILITIES AND FEATURES OF RICCARTON MALL MOTEL INCLUDE</h2>
    </div>
    <div style="padding: 150px 60px; color: #263238;">
      <v-row
        data-aos="fade-right"
        data-aos-duration="2000"
      >
        <v-col class="pa-10">
          <h4 style="word-wrap: break-all;white-space: pre-wrap">{{description}}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="6"
          md="6"
          sm="12"
          xs="12"
        >
          <div
            data-aos="fade-down"
            data-aos-duration="1000"
            style="display:flex; align-items: center;"
          >
            <v-avatar
              size="100"
              color="#263238"
              class="ma-5"
            >
              <v-icon
                size="40"
                color="white"
              >mdi-wifi-strength-4</v-icon>
            </v-avatar>
            <p style="display: inline-block; font-weight: bold">Wireless broadband available in all rooms</p>
          </div>
          <div
            data-aos="fade-down"
            data-aos-duration="1000"
            style="display:flex; align-items: center;"
          >
            <v-avatar
              size="100"
              color="#263238"
              class="ma-5"
            >
              <v-icon
                size="40"
                color="white"
              >mdi-car</v-icon>
            </v-avatar>
            <p style="display: inline-block; font-weight: bold">Free on site parking</p>
          </div>
          <div
            data-aos="fade-down"
            data-aos-duration="1000"
            style="display:flex; align-items: center;"
          >
            <v-avatar
              size="100"
              color="#263238"
              class="ma-5"
            >
              <v-icon
                size="40"
                color="white"
              >mdi-ev-station</v-icon>
            </v-avatar>
            <p style="display: inline-block; font-weight: bold">EV Charging</p>
          </div>
          <div
            data-aos="fade-down"
            data-aos-duration="1000"
            style="display:flex; align-items: center;"
          >
            <v-avatar
              size="100"
              color="#263238"
              class="ma-5"
            >
              <v-icon
                size="40"
                color="white"
              >mdi-youtube-tv</v-icon>
            </v-avatar>
            <p style="display: inline-block; font-weight: bold">Smart TV</p>
          </div>
          <div
            data-aos="fade-down"
            data-aos-duration="1000"
            style="display:flex; align-items: center;"
          >
            <v-avatar
              size="100"
              color="#263238"
              class="ma-5"
            >
              <v-icon
                size="40"
                color="white"
              >mdi-seat</v-icon>
            </v-avatar>
            <p style="display: inline-block; font-weight: bold">Cot and highchair available</p>
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          md="6"
          sm="12"
          xs="12"
        >
          <div
            data-aos="fade-down"
            data-aos-duration="1000"
            style="display:flex; align-items: center;"
          >
            <v-avatar
              size="100"
              color="#263238"
              class="ma-5"
            >
              <v-icon
                size="40"
                color="white"
              >mdi-phone</v-icon>
            </v-avatar>
            <p style="display: inline-block; font-weight: bold">Telephone, hairdryer and electric blankets in all rooms</p>
          </div>
          <div
            data-aos="fade-down"
            data-aos-duration="1000"
            style="display:flex; align-items: center;"
          >
            <v-avatar
              size="100"
              color="#263238"
              class="ma-5"
            >
              <v-icon
                size="40"
                color="white"
              >mdi-washing-machine</v-icon>
            </v-avatar>
            <p style="display: inline-block; font-weight: bold">Laundry facilities</p>
          </div>
          <div
            data-aos="fade-down"
            data-aos-duration="1000"
            style="display:flex; align-items: center;"
          >
            <v-avatar
              size="100"
              color="#263238"
              class="ma-5"
            >
              <v-icon
                size="40"
                color="white"
              >mdi-spa</v-icon>
            </v-avatar>
            <p style="display: inline-block; font-weight: bold">Spa bath units available with Bathroom heaters</p>
          </div>
          <div
            data-aos="fade-down"
            data-aos-duration="1000"
            style="display:flex; align-items: center;"
          >
            <v-avatar
              size="100"
              color="#263238"
              class="ma-5"
            >
              <v-icon
                size="40"
                color="white"
              >mdi-microwave</v-icon>
            </v-avatar>
            <p style="display: inline-block; font-weight: bold">Fully equipped kitchens, including microwave</p>
          </div>
          <div
            data-aos="fade-down"
            data-aos-duration="1000"
            style="display:flex; align-items: center;"
          >
            <v-avatar
              size="100"
              color="#263238"
              class="ma-5"
            >
              <v-icon
                size="40"
                color="white"
              >mdi-snowflake</v-icon>
            </v-avatar>
            <p style="display: inline-block; font-weight: bold">Air Conditioning</p>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { API } from "aws-amplify";
import { getFacilities } from "../graphql/queries";

export default {
  mounted() {
    window.scroll(0, 0);
    this.getFacilities();
  },
  methods: {
    async getFacilities() {
      this.description = await API.graphql({
        query: getFacilities,
        authMode: "API_KEY",
      }).then((res) => {
        return res.data.getFacilities.description;
      });
    },
  },
  data: () => ({
    description: ``,
  }),
};
</script>

<style scoped>
@media only screen and (min-width: 699px) {
  p {
    display: block;
  }
}

@media only screen and (max-width: 700px) {
  p {
    display: flex;
  }
}
</style>
