<template>
  <div
    style="z-index: 4;"
    @click="toggleDrawer"
  >
    <v-navigation-drawer
      style="position: fixed"
      v-model="drawer"
      absolute
      temporary
      right
    >
      <a :href="date">
        <v-btn
          tile
          block
          elevation="0"
          class="pa-10"
          color="#263238"
          style="color:white;"
        >
          BOOK ONLINE
        </v-btn>
      </a>
      <v-btn
        :color="url == 'home' ? '#ddd':'#F5F5F5'"
        block
        elevation="0"
        class="pa-10"
        @click="navigate('')"
      >
        Home
      </v-btn>
      <v-btn
        :color="url == 'information' ? '#ddd':'#F5F5F5'"
        block
        elevation="0"
        class="pa-10"
        @click="navigate('information')"
      >
        Attractions
      </v-btn>
      <v-btn
        :color="url == 'facilities' ? '#ddd':'#F5F5F5'"
        block
        elevation="0"
        class="pa-10"
        @click="navigate('facilities')"
      >
        Facilities
      </v-btn>
      <v-btn
        block
        elevation="0"
        class="pa-10"
        @click="navigate('contact')"
      >
        Contact
      </v-btn>
    </v-navigation-drawer>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapMutations } from "vuex";

export default {
  mounted() {
    this.getDate();
    this.initNavigate();
  },
  computed: {
    ...mapGetters({
      drawer: "drawer",
      url: "url",
    }),
    drawer: {
      get() {
        return this.$store.getters.drawer;
      },
      set(newDrawer) {
        return newDrawer;
      },
    },
  },
  methods: {
    ...mapMutations(["toggleDrawer", "navigator", "setContact"]),
    getDate() {
      let d = moment().format("L");

      let day = d.split("/")[1];
      let month = d.split("/")[0];
      let year = d.split("/")[2];

      this.date = `https://secure.staah.com/common-cgi/properties/b2c/Booknow.pl?action=showpage&MotelId=2474&checkIn=${year}-${month}-${day}&unk=1292`;
    },
    navigate(path) {
      const url = window.location.pathname.split("/");
      if (url[1] !== path) {
        this.$router.push(`/${path}`);
      }

      if (path == "") {
        this.navigator("home");
      } else {
        this.navigator(path);
      }
    },
    initNavigate() {
      const url = window.location.pathname.split("/");
      if (url[1] == "") {
        this.navigator("home");
      } else {
        this.navigator(url[1]);
      }
    },
    contact() {
      window.scroll(
        0,
        document.getElementById("form").offsetTop +
          document.getElementById("form").offsetHeight
      );
    },
  },
  data: () => ({
    items: [
      { title: "Home", icon: "mdi-home", url: "/" },
      { title: "Information", icon: "mdi-information", url: "/information" },
      { title: "Facilities", icon: "mdi-view-dashboard", url: "/facilities" },
      { title: "Contact Us", icon: "mdi-forum", url: "/contact" },
    ],
    date: "",
  }),
};
</script>
