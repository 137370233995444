import Vue from 'vue';
// import store from "../store";
import VueRouter from 'vue-router';

import Home from '../pages/Home';
import Information from '../pages/Information';
import Facilities from '../pages/Facilities';
import Contact from '../pages/Contact';
import AuthPage from '../pages/Auth';

import Admin from '../pages/Admin';

import { Auth } from 'aws-amplify';
Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/information',
		name: 'Information',
		component: Information,
	},
	{
		path: '/facilities',
		name: 'Facilities',
		component: Facilities,
	},
	{
		path: '/contact',
		name: 'Contact',
		component: Contact,
	},
	{
		path: '/auth',
		name: 'Auth',
		component: AuthPage,
	},
	{
		path: '/admin',
		name: 'Admin',
		component: Admin,
		meta: { requiresAuth: true },
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
	const isAuthenticated = await Auth.currentAuthenticatedUser()
		.then(user => {
			if (user) {
				return true;
			} else {
				return false;
			}
		})
		.catch(err => console.log(err));

	if (requiresAuth && !isAuthenticated) {
		Auth.signOut();
		next({ path: '/' });
	} else {
		next();
	}
});

export default router;
