<template>
  <div>
    <v-container
      style="height: 100vh"
      fill-height
    >
      <v-row
        justify="center"
        align="center"
      >
        <v-col cols="12">
          <v-card
            v-if="!isRegister"
            class="mx-auto"
            max-width="500"
          >
            <v-card-title>
              Login
            </v-card-title>
            <v-form
              ref="auth"
              @submit.prevent="login"
            >
              <v-container>

                <v-text-field
                  v-model="email"
                  label="Email"
                  :rules="emailRules"
                >
                </v-text-field>
                <v-text-field
                  :rules="[
        v => !!v || 'Field is required']"
                  :append-icon="hidePassword ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="hidePassword = !hidePassword"
                  :type="hidePassword ? 'password' :'text'"
                  v-model="password"
                  label="Password"
                >
                </v-text-field>

              </v-container>
              <v-card-actions>

                <v-spacer></v-spacer>
                <!-- <v-btn
                  @click="isRegister = !isRegister"
                  text
                >
                  Sign up
                </v-btn> -->
                <v-btn
                  text
                  @click="openForgotPassword"
                >
                  Forgot Password
                </v-btn>
                <v-btn
                  type="submit"
                  :loading="isLoading"
                  text
                >
                  Login
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
          <v-card
            v-else
            max-width="500"
            class="mx-auto"
          >
            <v-card-title>
              Register
            </v-card-title>
            <v-container>
              <v-form>
                <v-text-field
                  v-model="registerationEmail"
                  label="Email"
                >
                </v-text-field>
                <v-text-field
                  v-model="registerationPassword"
                  label="Password"
                >
                </v-text-field>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                @click="isRegister = !isRegister"
              >
                Login
              </v-btn>
              <v-btn
                color="green"
                @click="register"
              >
                Submit
              </v-btn>
              <v-btn @click="confirmAcc">Confirm Code</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      max-width="300"
      v-model="errorDialog"
    >
      <v-card
        max-width="300"
        color="red"
        dark
      >
        <v-card-title>
          Error
        </v-card-title>
        <v-card-text>
          {{ errorMessage }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="errorDialog = false"
            text
            dark
          >
            Okay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="forgotPasswordDialog"
      max-width="500"
    >
      <v-card
        max-width="500"
        v-if="!isChangePassword"
      >
        <v-card-title>
          Enter Email
        </v-card-title>

        <v-form
          ref="forgetForm"
          @submit.prevent="forgotPasswordSendCode"
        >
          <v-container>

            <v-text-field
              label="Email"
              :rules="emailRules"
              v-model="forgetEmail"
            >

            </v-text-field>

          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="forgotPasswordDialog = !forgotPasswordDialog"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              type="submit"
            >Submit</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <v-card v-else>
        <v-card-title>
          Change Password
        </v-card-title>
        <v-form
          ref="passwordChange"
          @submit.prevent="changePasswordConfirm"
        >
          <v-container>
            <v-text-field
              disabled
              v-model="passwordChangeEmail"
              label="Email"
            >
            </v-text-field>
            <v-text-field
              v-model="passwordChangeCode"
              label="Code"
              :rules="[v => !!v || 'Field Required']"
            >
            </v-text-field>
            <v-text-field
              type="password"
              v-model="passwordChangePassword"
              label="Password"
              :rules="[v => !!v || 'Field Required']"
            >
            </v-text-field>
            <v-text-field
              type="password"
              v-model="passwordChangePasswordConfirm"
              :rules="[v => v == passwordChangePassword || 'Password does not match' ]"
              label="Confirm Password"
            >
            </v-text-field>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :loading="isLoading"
              text
              @click="isChangePassword = !isChangePassword"
            >
              Resend
            </v-btn>
            <v-btn
              :loading="isLoading"
              type="submit"
              text
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
export default {
  mounted() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          this.$router.push("/admin");
        }
      })
      .catch((err) => console.log(err));
  },
  data: () => ({
    forgetEmail: "",
    changePassword: "",
    isChangePassword: false,
    forgotPasswordDialog: false,
    hidePassword: true,
    confirmCode: "639423",
    errorDialog: false,
    errorMessage: "",
    isRegister: false,
    isLoading: false,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    email: "",
    password: "",
    registerationEmail: "",
    registerationPassword: "",
    passwordChangeEmail: "",
    passwordChangeCode: "",
    passwordChangePassword: "",
    passwordChangePasswordConfirm: "",
  }),
  methods: {
    openError(error) {
      this.errorDialog = true;
      this.errorMessage = error;
    },
    openForgotPassword() {
      this.forgotPasswordDialog = true;
    },
    forgotPasswordSendCode() {
      let isValid = this.$refs.forgetForm.validate();
      if (isValid) {
        Auth.forgotPassword(this.forgetEmail)
          .then((data) => {
            this.isChangePassword = true;
            this.passwordChangeEmail = this.forgetEmail;
          })
          .catch((err) => this.openError(err));
      }
    },
    async changePasswordConfirm() {
      let isValid = this.$refs.passwordChange.validate();
      if (isValid) {
        try {
          this.isLoading = true;
          await Auth.forgotPasswordSubmit(
            this.passwordChangeEmail,
            this.passwordChangeCode,
            this.passwordChangePasswordConfirm
          );
          this.isLoading = false;
          this.forgotPasswordDialog = false;
          this.isChangePassword = false;
        } catch (error) {
          this.openError(error);
        }
      }
    },
    async login() {
      let isValid = this.$refs.auth.validate();
      if (isValid) {
        try {
          this.isLoading = true;
          await Auth.signIn(this.email, this.password);
          this.$router.push("/admin");
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.openError(error);
        }
      }
    },
    async register() {
      try {
        await Auth.signUp({
          username: this.registerationEmail,
          password: this.registerationPassword,
          attributes: {
            email: this.registerationEmail,
          },
        });
      } catch (error) {
        this.openError(error);
      }
    },
    async confirmAcc() {
      try {
        await Auth.confirmSignUp(
          "vaughn.gigataras@gmail.com",
          this.confirmCode
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>