<template>
  <v-dialog
    persistent
    v-model="prompt"
    :style="{
        width: '100vw',
        height: '100vh', 
        zIndex: '5',
        backgroundColor: 'rgba(0,0,0,0.5)'
    }"
  >
    <div style="width: 100vw; height: 100vh; background-color:transparent; position:fixed;top:0;left:0">
    </div>
    <v-card
      style="display:flex; flex-direction: column; position:absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)"
      elevation="24"
      class="prompt"
    >
      <div style="display: flex; padding: 20px; background-color: #eee; position: relative">
        <v-icon
          style="position:absolute; right: 10px ;top: 10px; background-color: #eee"
          class="pa-3"
          @click="closePrompt"
        >
          mdi-close
        </v-icon>
        <v-spacer />
        <div
          class="pt-10 pl-5 pr-5 pb-0"
          style="text-align:center;"
        >
          <h4 style="font-size:15px">{{title}}</h4>
          <p style="color: #888; font-size: 12px">{{subtitle}}</p>
        </div>
        <v-spacer />
      </div>
      <v-carousel
        style="flex: 1;"
        :cycle="true"
        :show-arrows="true"
        height="100%"
        :hide-delimiter-background="true"
        :hide-delimiters="true"
        progress-color="#888"
      >
        <v-carousel-item
          style="width: 100%; height: 100%"
          v-for="(slide, i) in slides"
          :key="i"
        >
          <img
            class="image ma-auto"
            style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)"
            :src="slide"
          />
        </v-carousel-item>
      </v-carousel>

      <a
        :href="date"
        target="_blank"
      >
        <v-btn
          tile
          block
          class="pa-16"
          color="#263238"
          style="color:white"
        >BOOK ONLINE</v-btn>
      </a>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { mapGetters, mapMutations } from 'vuex';

export default {
  mounted () {
    this.getDate();
  },
  data: () => ({
    date: '',
  }),
  computed: {
    ...mapGetters(['title', 'subtitle', 'slides']),
    prompt: {
      get () {
        return this.$store.getters['prompt']
      },
      set (value) {
        this.$store.commit('setPrompt', value)
      }
    }
  },
  methods: {
    ...mapMutations(['closePrompt']),
    getDate () {
      let d = moment().format('L');

      let day = d.split('/')[1];
      let month = d.split('/')[0];
      let year = d.split('/')[2];

      this.date = `https://secure.staah.com/common-cgi/properties/b2c/Booknow.pl?action=showpage&MotelId=2474&checkIn=${year}-${month}-${day}&unk=1292`;
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 600px) {
  .prompt {
    width: 80%;
    height: 90%;
  }

  .image {
    width: 50%;
  }
}
@media screen and (max-width: 1201px) {
  .prompt {
    width: 100%;
    height: 100%;
  }

  .image {
    width: 100%;
  }
}
</style>
